import { AttributeValue, DynamoDBClient, GetItemCommand, GetItemCommandInput, GetItemCommandOutput, QueryOutput, ScanCommand } from '@aws-sdk/client-dynamodb';
import { DynamoDBDocumentClient, QueryCommand, ScanCommandInput, ScanCommandOutput } from '@aws-sdk/lib-dynamodb';
import { Auth } from 'aws-amplify';

export interface DynamoApiProps {
  /**
     * The AWS DynamoDB Table name.
     */
  tableName: string;

  /**
     * The AWS Region.
     */
  region: string;

  /**
     * The index map.
     */
  indexMap: { [key: string]: string };
}

export interface IDynamoApi {
  /**
     * @description Query documents by index.
     *
     * @param org
     * @param index
     * @param search
     */
  queryByIndex(org: string, index: string, search: string, userKey?: string): Promise<QueryOutput>;
}

export class DynamoApi implements IDynamoApi {
  tableName: string;
  region: string;
  indexMap: { [key: string]: string };
  constructor(props: DynamoApiProps) {
    this.tableName = props.tableName;
    this.region = props.region;
    this.indexMap = props.indexMap;
  }

  async queryByIndex(org: string, index: string, search?: string): Promise<QueryOutput> {
    const keyConditionExpression = 'AppSk = :app_sk';
    const params: any = {
      TableName: this.tableName,
      IndexName: index,
      Limit: 50,
      KeyConditionExpression: keyConditionExpression,
      ExpressionAttributeValues: {
        ':app_sk': `${org}#doc`,
      },
    };

    if (search) {
      params.KeyConditionExpression += ' and begins_with(#index_var, :index_var)';
      params.ExpressionAttributeValues[':index_var'] = search;
      params.ExpressionAttributeNames = {
        '#index_var': this.indexMap[index],
      };
    };

    return Auth.currentCredentials()
      .then(async (credentials) => {
        const client = new DynamoDBClient({
          region: 'us-east-1',
          credentials: Auth.essentialCredentials(credentials),
        });
        const documentClient = DynamoDBDocumentClient.from(client);
        const command = new QueryCommand(params);
        // @ts-expect-error
        return documentClient.send(command);
      });
  };

  async queryDocVersions(pk: string, sk: string): Promise<QueryOutput> {

    let KeyConditionExpression = 'AppSk = :app_sk';

    const params = {
      TableName: this.tableName,
      IndexName: 'idx_created',
      Limit: 20,
      KeyConditionExpression: KeyConditionExpression,
      ScanIndexForward: false,
      FilterExpression: 'begins_with(AppPk, :app_pk)',
      ExpressionAttributeValues: {
        ':app_sk': sk,
        ':app_pk': pk,
      },
    };

    return Auth.currentCredentials()
      .then(async (credentials) => {
        const client = new DynamoDBClient({
          region: 'us-east-1',
          credentials: Auth.essentialCredentials(credentials),
        });
        const documentClient = DynamoDBDocumentClient.from(client);
        const command = new QueryCommand(params);
        // @ts-expect-error
        return documentClient.send(command);
      });
  }

  async getItem(pk: { [key: string]: AttributeValue }, table?: string): Promise<GetItemCommandOutput> {
    const params: GetItemCommandInput = {
      TableName: table || this.tableName,
      Key: pk,
    };

    return Auth.currentCredentials()
      .then(async (credentials) => {
        const client = new DynamoDBClient({
          region: 'us-east-1',
          credentials: Auth.essentialCredentials(credentials),
        });
        const documentClient = DynamoDBDocumentClient.from(client);
        const command = new GetItemCommand(params) as any;
        return documentClient.send(command);
      });
  }

  async scan(table?: string, limit?: number, nextToken?: string): Promise<ScanCommandOutput> {
    const params: ScanCommandInput = {
      TableName: table || this.tableName,
      Limit: limit || 50,
    };

    if (nextToken) {
      params.ExclusiveStartKey = {
        Key: { S: nextToken },
      };
    }

    return Auth.currentCredentials()
      .then(async (credentials) => {
        const client = new DynamoDBClient({
          region: 'us-east-1',
          credentials: Auth.essentialCredentials(credentials),
        });
        const documentClient = DynamoDBDocumentClient.from(client);
        const command = new ScanCommand(params) as any;
        return documentClient.send(command);
      });
  }
}