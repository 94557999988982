import { useTheme, View } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Header, SearchBox, DocumentTable, DetailsModal, AddDocumentModal } from '../../components';
import { IColumn } from '../../components/document-table/document-table';
import config from '../../config';
import { useStores } from '../../models';
import { documentTypeCheck } from '../../utils/conversions/document-type';

const documentColumns: IColumn[] = [
  { name: 'Document Name', key: 'Name' },
  { name: 'Latest', key: 'UpdatedAt', isEpoch: true },
  { name: 'Type', key: 'ContentType', isContentType: true },
  { name: 'Size', key: 'Size', isSize: true },
];

export const HomeScreen = observer(() => {

  const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);
  const [addDocumentCurrentKey, setAddDocumentCurrentKey] = useState<string | undefined>(undefined);
  const [addDocumentCurrentName, setAddDocumentCurrentName] = useState<string | undefined>(undefined);

  const { tokens } = useTheme();
  const { currentUser, documentStore, documentVersionStore } = useStores();
  const { s3Api } = documentStore.environment;

  useEffect(() => {
    documentStore.getDocuments(currentUser.activeOrg, '');

  }, [documentStore, currentUser]);

  const handleSearch = (searchTerm: string) => {
    documentStore.getDocuments(currentUser.activeOrg, searchTerm);
  };

  const handleBackToPortal = () => {
    currentUser.setAuthenticated(false); // Clear logged in state on return to portal
    window.location.replace(config.portalUrl);
  };

  // TODO - Cleanup and move to dedicated component
  const setSelectedModalDocument = (document: any | null) => {
    if (document != null) {
      const pk = document.AppPk.replace('doc', 'doc-ver');
      const sk = pk.substring(0, pk.indexOf('doc-ver')) + 'doc-ver';
      documentVersionStore.getDocuments(pk, sk);
      documentStore.setSelectedDocumentPreview(document.Path);
      documentStore.setSelectedDocument(document.AppPk);
    } else {
      documentVersionStore.cleanupDocuments();
      documentStore.setSelectedDocument(document);
    }
  };

  const addDocumentCleanup = () => {
    setAddDocumentCurrentKey(undefined);
    setAddDocumentCurrentName(undefined);
    setIsAddDocumentModalOpen(false);

    // wait 10 seconds
    setTimeout(() => {
      documentStore.getDocuments(currentUser.activeOrg, '');
    }
    , 50000);
  };

  const handleOrgChange = (org: string) => {
    currentUser.setActiveOrg(org);
    documentStore.getDocuments(org, '');
  };

  return (
    <View backgroundColor={tokens.colors.background.primary} overflow='hidden' >
      <Header headerTitle='Document Manager' backToPortalButtonAction={() => handleBackToPortal()} onOrgChange={handleOrgChange} />
      <SearchBox searchAction={handleSearch} addDocumentAction={() => setIsAddDocumentModalOpen(true)} isAdmin={currentUser.isAdmin}/>
      <DocumentTable
        store={documentStore.documents}
        pk='AppPk'
        columns={documentColumns}
        detailsButtonAction={(item) => setSelectedModalDocument(item)}
        previewButtonAction={(item) => s3Api.getObject(item.Path)}
        downloadButtonAction={(item) => s3Api.getObject(item.Path, true, undefined, `${item.Name}.${documentTypeCheck(item.ContentType)}`)}
        updateButtonAction={currentUser.isAdmin ? (item) => {
          setAddDocumentCurrentKey(item.Path.substring(item.Path.lastIndexOf('/') + 1));
          setAddDocumentCurrentName(item.Name);
          setIsAddDocumentModalOpen(true);
        } : undefined}
        onRowClick={(_item) => console.log(null)}
      />
      <DetailsModal closeAction={() => setSelectedModalDocument(null)}/>
      <AddDocumentModal closeAction={() => addDocumentCleanup()} isOpen={isAddDocumentModalOpen} documentKey={addDocumentCurrentKey} documentName={addDocumentCurrentName} />
    </View>
  );
});