export interface StorageConfig {
  bucket: string;
  table: string;
  tableIndexMap: { [key: string]: string };
}

export interface Config {
  environment: string;
  region: string;
  userPoolId: string;
  clientId: string;
  identityPoolId: string;
  analyticsAppId: string;
  authDomain: string;
  redirectSignIn: string;
  redirectSignOut: string;
  storage: StorageConfig;
  portalUrl: string;
  [key: string]: any;
}

const config: Config = {
  environment: 'dev',
  region: 'us-east-1',
  userPoolId: 'us-east-1_pf8m5Riop',
  clientId: '2o9vo9eegaj4pk6m69t1pb7d83',
  identityPoolId: 'us-east-1:f286bbc3-684a-4c15-85e4-726dbd6348c9',
  analyticsAppId: '6bd7b8a4a95c4195af21065869dbbbc5',
  authDomain: 'auth.document-manager.kesportaldev.com',
  redirectSignIn: 'http://localhost:3000/callback',
  redirectSignOut: 'https://document-manager.kesportaldev.com/logout',
  storage: {
    bucket: 'dev-onpoint-document-manager-use1',
    table: 'dev-document-manager',
    tableIndexMap: {
      idx_name: 'Name',
      idx_owner: 'Owner',
      idx_created: 'CreatedAt',
    },
  },
  portalUrl: 'http://onpoint-portal.kesportaldev.com/',
};

export default config;