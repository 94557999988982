import { Pagination } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useStores } from '../../models';

export interface PdfViewProps {
  file?: any;
  width?: number;
  height?: number;
}

const PdfViewer = (props: PdfViewProps) => {

  const { documentStore } = useStores();

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, _setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const handleNextPage = () => {
    _setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    _setPageNumber(pageNumber - 1);
  };

  const handleOnChange = (newPageIndex: number, _prevPageIndex: number) => {
    _setPageNumber(newPageIndex);
  };

  return (
    <div>
      <Document file={props.file || documentStore.selectedDocumentPreviewUrl} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(err) => console.log(err)}>
        <Page width={props.width || 300} height={props.height || 400} pageNumber={pageNumber} />
      </Document>
      <Pagination
        currentPage={pageNumber}
        totalPages={numPages}
        onNext={handleNextPage}
        onPrevious={handlePreviousPage}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default observer(PdfViewer);