import Amplify, { Auth, Storage as AmplifyStorage, Analytics } from 'aws-amplify';
import { Component, ComponentProps } from 'react';
import { pdfjs } from 'react-pdf';
import reportWebVitals from '../utils/webVitals/reportWebVitals';
import awsconfig from './aws-config';
import { load } from './config';
import { Config } from './default-config';
// The below adds a worker source to the pdfjs library.
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export interface ConfigLoaderState {
  isLoaded: boolean;
  config?: any;
  isdisabled?: any;
}

export interface ConfigLoaderProps {
  ready: ComponentProps<any>['children'];
  loading?: Component<ComponentProps<any>, any>;
}

function browserStorage(type: string): Storage {
  if (type === 'local') {
    return window.localStorage;;
  }
  return sessionStorage;
}

function setup(config: Config) {
  Amplify.configure(awsconfig(config));
  Auth.configure({
    storage: browserStorage(config.browserStorage),
  });
  AmplifyStorage.configure({
    customerPrefix: {
      public: '/public',
      protected: '/protected',
      private: '/private',
    },
  });
  Analytics.configure({
    Analytics: {
      AWSPinpoint: {
        region: config.pinpointRegion || 'us-east-1',
      },
    },
  });
  Analytics.autoTrack('pageView', {
    enable: config.enableAnalytics || false,
    type: 'SPA',
  });

  if (config.environment === 'dev') {
    console.log('Config loaded:', config);
    reportWebVitals(console.log);
  }
}

export default class ConfigLoader extends Component {
  state: ConfigLoaderState;
  props: ConfigLoaderProps;
  constructor(props: ConfigLoaderProps) {
    super(props);
    this.props = props;
    this.state = { isLoaded: false };
  }


  componentDidMount() {
    // Once the configuration is loaded set `isLoaded` to true so we know to render our component
    load().then(config => {
      this.setState({ isLoaded: true, config: config });
    });
  }

  render() {
    const { props }: { props: ConfigLoaderProps } = this;
    // If we haven't yet loaded the config, show either a "splash" component provided via a `loading` props or return nothing.
    if (!this.state.isLoaded) {
      return props.loading ? props.loading : null;
    }
    // The config is loaded so show the component set on the `ready()` props
    setup(this.state.config);
    return props.ready(this.state.config);
  }
}