import { Theme } from '@aws-amplify/ui-react';

/**
 * App theme
 */
export const theme: Theme = {
  name: 'primary-theme',
  tokens: {
    colors: {
      font: {
        primary: { value: '#FFFFFF' },
        secondary: { value: '#FFFFFF' },
      },
      background: {
        primary: { value: '#282c34' },
      },
    },
  },
};