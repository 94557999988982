import { EventAttributes } from '@aws-amplify/analytics/lib-esm/types';
import { Analytics } from 'aws-amplify';

export interface IAnalyticsRecorder {
  setup(org: string, userKey: string): void;
  recordEventAsync(eventName: string, eventAttributes: {[key: string]: string}): Promise<any>;
}

export class AnalyticsRecorder implements IAnalyticsRecorder {
  environment: string;
  org?: string;
  userKey?: string;
  constructor(environment: string) {
    this.environment = environment;
  }

  setup(org: string, userKey: string) {
    this.org = org;
    this.userKey = userKey;
  }

  async recordEventAsync(eventName: string, eventAttributes: EventAttributes) {
    const attributes = {
      ...eventAttributes,
      ...{
        org: this.org!,
        userKey: this.userKey!,
      },
    };

    await Analytics.record({
      name: eventName,
      attributes: attributes,
    });
  }

}