import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { OrgModel, OrgSnapshot } from '../org/org';

export const OrgStoreModel = types
  .model('OrgStore')
  .props({
    orgs: types.optional(types.array(OrgModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    saveOrgs: (orgSnapshots: OrgSnapshot[]) => {
      self.orgs.replace(orgSnapshots);
    },
  }))
  .actions((self) => ({
    getOrgs: async () => {
      const api = self.environment.dynamoApi;
      const orgs: OrgSnapshot[] = await api.scan(`${self.environment.config.environment}-KES_Customer`, 500).then(res => {
        return res.Items!.map(item => {
          return {
            Key: item.Key.S,
            Name: item.Name.S,
          };
        });
      });
      self.saveOrgs(orgs);
    },
  }));

type OrgStoreType = Instance<typeof OrgStoreModel>;
export interface OrgStore extends OrgStoreType { }
type OrgStoreSnapshotType = SnapshotOut<typeof OrgStoreModel>;
export interface OrgStoreSnapshot extends OrgStoreSnapshotType { }
export const createOrgStoreDefaultModel = () =>
  types.optional(OrgStoreModel, {} as any);
