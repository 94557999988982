import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { Auth, Storage } from 'aws-amplify';
import { AnalyticsRecorder } from '../../utils/event-recorder';

export interface S3ApiProps {
  region: string;
  bucket: string;
  level: string;
  recorder?: AnalyticsRecorder;
}

export class S3Api {
  region: string;
  bucket: string;
  level: string;
  recorder?: AnalyticsRecorder;
  constructor(props: S3ApiProps) {
    this.region = props.region;
    this.bucket = props.bucket;
    this.level = props.level;
    this.recorder = props.recorder;
    Storage.configure({
      region: this.region,
      bucket: this.bucket,
      level: this.level,
    });
  }

  async getObject(key: string, download?: boolean, expires?: number, fileDownloadName?: string): Promise<void> {
    let _key = key;
    _key = _key.replace('public/', '');
    let props: any = { track: true, expires: expires || 60 };
    if (download) {
      props = { ...props, contentType: 'application/octet-stream', contentDisposition: fileDownloadName ? 'attachment; filename="' + fileDownloadName + '"' : 'attachment' };
    }
    const url = await Storage.get(_key, props);
    window.open(url);
  }

  async getSignedUrl(key: string, versionId?: string | undefined, download?: boolean, expires?: number, openUrl: boolean = true, downloadFileName?: string): Promise<string> {
    const params: any = {
      Bucket: this.bucket,
      Key: key,
      Expires: expires || 60,

    };
    if (versionId) {
      params.VersionId = versionId;
    }
    if (download) {
      downloadFileName ? params.ResponseContentDisposition = 'attachment; filename="' + downloadFileName + '"' : params.ResponseContentDisposition = 'attachment';
    }

    this.recordEvent(key, versionId, download);

    return Auth.currentCredentials()
      .then(async (credentials) => {
        const client = new S3Client({
          region: this.region,
          credentials: Auth.essentialCredentials(credentials),
        });
        const command = new GetObjectCommand(params);
        const url = await getSignedUrl(client, command, params);
        if (openUrl) {
          window.open(url);
        }
        return url;
      });
  }

  async getObjectBodyBuffer(key: string): Promise<ArrayBuffer> {

    const params = {
      Bucket: this.bucket,
      Key: key,
    };

    return Auth.currentCredentials()
      .then(async (credentials) => {
        const client = new S3Client({
          region: this.region,
          credentials: Auth.essentialCredentials(credentials),
        });
        const command = new GetObjectCommand(params);
        const blob: Blob = await (await client.send(command)).Body as Blob;
        return new Response(blob).arrayBuffer();
      });
  }

  async recordEvent(key: string, versionId?: string, download?: boolean): Promise<void> {
    if (this.recorder) {
      if (this.recorder) {
        this.recorder.recordEventAsync(
          'document-access', {
            key: key,
            action: download ? 'download' : 'preview',
            versionId: versionId || 'latest',
          });
      }
    }
  }
}