import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * Model description here for TypeScript hints.
 */
export const DocumentVersionModel = types
  .model('DocumentVersion')
  .props({
    AppPk: types.identifier,
    AppSk: types.string,
    CreatedAt: types.string,
    LatestVersionId: types.string,
    Size: types.number,
    ContentType: types.string,
    Path: types.string,
  });

type DocumentVersionType = Instance<typeof DocumentVersionModel>
export interface DocumentVersion extends DocumentVersionType {}
type DocumentVersionSnapshotType = SnapshotOut<typeof DocumentVersionModel>
export interface DocumentVersionSnapshot extends DocumentVersionSnapshotType {}
export const createDocumentVersionDefaultModel = () => types.optional(DocumentVersionModel, {} as DocumentVersion);
