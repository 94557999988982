import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const DocumentModel = types
  .model('Document')
  .props({
    AppPk: types.identifier,
    AppSk: types.string,
    Name: types.string,
    CreatedAt: types.string,
    UpdatedAt: types.string,
    LatestVersionId: types.string,
    Size: types.number,
    ContentType: types.string,
    Path: types.string,
  });

type DocumentType = Instance<typeof DocumentModel>;
export interface Document extends DocumentType {}
type DocumentSnapshotType = SnapshotOut<typeof DocumentModel>;
export interface DocumentSnapshot extends DocumentSnapshotType {}
export const createDocumentDefaultModel = () =>
  types.optional(DocumentModel, {} as Document);
