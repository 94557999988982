/**
 * @description - Get file extension from content type.
 * @param contentType
 * @returns DocumentType
 */
export const documentTypeCheck = (contentType: string) => {
  switch (contentType) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx';
    case 'application/pdf':
      return 'pdf';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.ms-excel':
      return 'xls';
    case 'application/vnd.ms-powerpoint':
      return 'ppt';
    default:
      return 'unknown';
  }
};