import { View, Flex, Text, useTheme, Image, Button, SelectField } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { useStores } from '../../models';
import '@aws-amplify/ui-react/styles.css';

export interface HeaderProps {
  headerTitle: string;
  backToPortalButtonAction: () => void;
  onOrgChange: (org: string) => void;
}

const Header = (props: HeaderProps) => {

  const { tokens } = useTheme();

  const { currentUser, orgStore } = useStores();

  useEffect(() => {
    orgStore.getOrgs();
    if (currentUser.isAdmin === true) {
      currentUser.getActiveOrgName();
    }
  }, [orgStore, currentUser]);

  return (
    <View backgroundColor={{ base: tokens.colors.background.primary, large: tokens.colors.background.primary }} position='relative' padding={10} marginLeft='100px' marginRight='auto'>
      <Flex direction='row' justifyContent='flex-start'>
        <Image
          src='logo.svg'
          width='150px'
          height='150px'
          alt='OnPoint Logo'
        />
        <Text fontSize={tokens.fontSizes.xl} fontWeight={'bold'} paddingLeft={10} paddingTop={60} style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          {props.headerTitle}
        </Text>
        {
          currentUser.activeOrg && currentUser.isAdmin &&
                    <View>
                      <SelectField
                        placeholder={`${currentUser.activeOrgName}`}
                        size='small'
                        width='400px'
                        marginRight={20} marginTop={20}
                        defaultValue={currentUser.activeOrg}
                        label="Change Organization"
                        labelHidden={true}
                        onChange={(e: any) => props.onOrgChange(e.target.value)}
                      >
                        <option value="d1b73e2a-ce2f-4731-b36f-bfedcac3e3f8">Default Parent Admin</option>
                        {orgStore.orgs.map((org: any) => {
                          if (org.Key !== currentUser.activeOrg && org.Key !== 'd1b73e2a-ce2f-4731-b36f-bfedcac3e3f8') {
                            return <option key={org.Key} value={org.Key}>{org.Name}</option>;
                          } else {
                            return null;
                          }
                        })}
                      </SelectField>
                    </View>

        }
        <Button marginRight={20} marginTop={20} size='small' fontSize={15} height='30px' onClick={() => props.backToPortalButtonAction()}>
          <Text>Back to Portal</Text>
        </Button>
      </Flex>
    </View>
  );
};

export default Header;