import { Flex, Heading, View, ScrollView, Button, useTheme } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react-lite';
import ReactModal from 'react-modal';
import { useStores } from '../../models';
import { unixToDateTime } from '../../utils/conversions/date-time';
import { documentTypeCheck } from '../../utils/conversions/document-type';
import DocumentTable, { IColumn } from '../document-table/document-table';
import PdfViewer from '../pdf-viewer/pdf-viewer';

const documentColumns: IColumn[] = [
  { name: 'Created', key: 'CreatedAt', isEpoch: true },
];

const autoFitModal = (size: number, boarder: number) => {
  return size - size / 100 - boarder;
};

export interface DetailsModalProps {
  closeAction: () => void;
}

const DetailsModal = (props: DetailsModalProps) => {
  const { tokens } = useTheme();
  const { documentStore, documentVersionStore } = useStores();
  const { documentVersions } = documentVersionStore;

  const { s3Api } = documentStore.environment;

  const onClose = () => {
    props.closeAction();
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={documentStore.selectedDocument !== null}
      preventScroll={true}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          color: tokens.colors.font.primary.value,
          width: autoFitModal(window.innerWidth, 100),
          height: autoFitModal(window.innerHeight, 100),
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: tokens.colors.background.primary.value,
          border: 'none',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Flex direction='row' alignItems='flex-start'>
        <Flex
          direction='column'
          alignItems='flex-start'>
          <Flex direction={'row'}>
            <Heading level={5} color={tokens.colors.font.primary.value} >
              {documentStore.selectedDocument?.Name}
            </Heading>
            <Button
              variation='primary'
              onClick={() => onClose()}
              style={{
                position: 'absolute',
                right: '2%',
                top: '2%',
              }}>Close</Button>
          </Flex>
          <Flex>
            {documentStore.selectedDocument?.ContentType === 'application/pdf' && (
              documentStore.selectedDocumentPreviewUrl != null && (
                <PdfViewer />
              ))}
            <View>
              <ScrollView
                orientation='horizontal'
                height={autoFitModal(window.innerHeight, 200)}
                width={autoFitModal(window.innerWidth, 500)}
              >
                <DocumentTable
                  store={documentVersions}
                  pk='AppPk'
                  columns={documentColumns}
                  width={autoFitModal(window.innerWidth, 500)}
                  rowWidth='90%'
                  previewButtonAction={(item) => s3Api.getSignedUrl(item.Path, item.LatestVersionId)}
                  downloadButtonAction={(item) => s3Api.getSignedUrl(item.Path, item.LatestVersionId, true, undefined, undefined, `${documentStore.selectedDocument?.Name}-${unixToDateTime(item.CreatedAt)}.${documentTypeCheck(item.ContentType)}`)}
                  onRowClick={(item) => documentStore.setSelectedDocumentPreview(item.Path, item.LatestVersionId)}
                />
              </ScrollView>
            </View>
          </Flex>
        </Flex>
      </Flex>
    </ReactModal>
  );
};

export default observer(DetailsModal);