import { AmplifyProvider, Placeholder } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RootStore, RootStoreProvider, setupRootStore } from '../models';
import { HomeScreen } from '../screens';
import { theme } from '../utils/theme';
import { CallbackRoute } from './callback-route';
import { ProtectedRoute } from './protected-route';

export default function AppRouter(): JSX.Element {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  useEffect(() => {
    ; (async () => {
      setupRootStore().then(setRootStore);
    })();
  }, []);

  rootStore?.currentUser.setAuthenticated(false);

  return (
  /**
         * If `rootStore` is undefined, we're still waiting for data from
         * `setupRootStore`. Once we have that data, we can render the root
         */
    rootStore ? (
      <RootStoreProvider value={rootStore}>
        <AmplifyProvider theme={theme}>
          <Router>
            <Routes>
              <Route path='/callback' element={<CallbackRoute />} />
              <Route path='/' element={
                <ProtectedRoute>
                  <HomeScreen />
                </ProtectedRoute>
              } />
            </Routes>
          </Router>
        </AmplifyProvider>
      </RootStoreProvider>
    ) : (
      <Placeholder size='large'>Loading...</Placeholder> // If we don't have a rootStore yet, show a loading screen
    )
  );
}