const awsconfig = (config: any) => {
  return {
    aws_project_region: config.region,
    aws_cognito_region: config.region,
    aws_user_pools_id: config.userPoolId,
    aws_user_pools_web_client_id: config.clientId,
    aws_cognito_identity_pool_id: config.identityPoolId,
    aws_mobile_analytics_app_region: config.region,
    aws_mobile_analytics_app_id: config.analyticsAppId,
    oauth: {
      domain: config.authDomain,
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: config.redirectSignIn,
      redirectSignOut: config.redirectSignOut,
      responseType: 'code',
    },
  };
};

export default awsconfig;