import { View, useTheme } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { observer } from 'mobx-react-lite';
import { useLayoutEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useStores } from '../models';

/**
 * @description A route to handle authentication callbacks.
 */
export const CallbackRoute = observer(() => {

  const { tokens } = useTheme();
  const { currentUser } = useStores();

  useLayoutEffect (() => {
    (async () => {

      const setAuthenticated = () => {
        Auth.currentUserInfo().then(user => {
          const org = user.attributes['custom:org'];
          const userKey = user.attributes['custom:userKey'];
          const isAdmin = user.attributes['custom:isOnPointAdmin'] === 'true';
          if (currentUser.activeOrg === '') {
            currentUser.setActiveOrg(org);
          }
          currentUser.setUserKey(userKey);
          currentUser.setAdmin(isAdmin);
          currentUser.environment.analyticsRecorder.setup(org, userKey); // Bootstrap analytics with active org and userKey
        });
        currentUser.setAuthenticated(true);
      };

      await Auth.currentAuthenticatedUser().then(user => {
        user ? setAuthenticated() : currentUser.setAuthenticated(false);
      });
    })();
  }, [currentUser]);

  return (
    currentUser.isAuthenticated ?
      <Navigate to='/' replace />
      : (
        <View
          backgroundColor={tokens.colors.background.primary}
          overflow='hidden'
        >
        </View>
      )
  );
});