import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { DocumentVersionModel, DocumentVersionSnapshot } from '../document-version/document-version';
import { withEnvironment } from '../extensions/with-environment';

/**
 * Model description here for TypeScript hints.
 */
export const DocumentVersionStoreModel = types
  .model('DocumentVersionStore')
  .props({
    documentVersions: types.optional(types.array(DocumentVersionModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    // TODO - Evaluate where this should live
    queryRecorder: (eventName: string, itemsReturned?: number) => {
      const event: any = {};

      if (itemsReturned) {
        event.itemsReturned = itemsReturned;
      }

      self.environment.analyticsRecorder.recordEventAsync(eventName, event);
    },
  }))
  .actions((self) => ({
    saveDocuments: (documentVersionSnapshots: DocumentVersionSnapshot[]) => {
      self.documentVersions.replace(documentVersionSnapshots);
    },
    cleanupDocuments: () => {
      self.documentVersions.replace([]);
    },
  }))
  .actions(self => ({
    getDocuments: (pk: string, sk: string, pkConvert: boolean = false) => {
      if (pkConvert) {
        pk = pk.replace('doc', 'doc-ver');
        sk = pk.substring(0, pk.indexOf('doc-ver')) + 'doc-ver';
      }

      const api = self.environment.dynamoApi;
      return api.queryDocVersions(pk, sk).then(res => {
        const documentVersions: DocumentVersionSnapshot[] = res.Items!.map(item => {
          return {
            AppPk: item.AppPk as unknown as string,
            AppSk: item.AppSk as unknown as string,
            CreatedAt: String(item.CreatedAt) as unknown as string,
            LatestVersionId: item.VersionId as unknown as string,
            Size: item.Size as unknown as number,
            ContentType: item.ContentType as unknown as string,
            Path: item.Key as unknown as string,
          };
        },
        );
        self.saveDocuments(documentVersions);
        self.queryRecorder('document-version-query', res.Items!.length);
      });
    },
  }));

type DocumentVersionStoreType = Instance<typeof DocumentVersionStoreModel>
export interface DocumentVersionStore extends DocumentVersionStoreType { }
type DocumentVersionStoreSnapshotType = SnapshotOut<typeof DocumentVersionStoreModel>
export interface DocumentVersionStoreSnapshot extends DocumentVersionStoreSnapshotType { }
export const createDocumentVersionStoreDefaultModel = () => types.optional(DocumentVersionStoreModel, {} as DocumentVersionSnapshot);
