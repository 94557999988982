import { Text, View, Flex, TextField, useTheme, Button } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
export interface TableSearchProps {
  searchAction: (searchTerm: string) => void;
  addDocumentAction: () => void;
  isAdmin: boolean;
}

const SearchBox = (props: TableSearchProps) => {

  const { tokens } = useTheme();

  return (
    <View width='90%' marginTop='20px' marginLeft='auto' marginRight='auto'>
      <Flex justifyContent='flex-start' direction={{ base: 'column', large: 'row' }}>
        <TextField
          label='Search'
          labelHidden={true}
          placeholder='Search by document name ...'
          marginLeft='auto'
          marginRight='auto'
          width='50vh'
          color={tokens.colors.font.primary}
          onChange={(e: any) => props.searchAction(e.target.value)}
        />
        {props.isAdmin &&
                    <Button size='small' onClick={() => props.addDocumentAction()}>
                      <Text as="span" fontSize="15px">
                            Add Document
                      </Text>
                    </Button>
        }
      </Flex>
    </View>
  );
};

export default SearchBox;