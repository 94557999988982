/**
 * @description - Convert unix (EPOCH) to local time.
 * @param unix
 * @returns DateTime as string
 */
export const unixToDateTime = (unix: string, includeTime?: boolean) => {
  if (includeTime) {
    return new Date(Number(unix) * 1000).toLocaleString();
  } else {
    return new Date(Number(unix) * 1000).toLocaleDateString();
  }
};