import { Flex, View, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react-lite';
import { unixToDateTime, bytesToKb, documentTypeCheck } from '../../utils/conversions';
import '@aws-amplify/ui-react/styles.css';
import './styles.css'; // override amplify default hoover color.

export interface IColumn {
  name: string;
  key: string;
  isEpoch?: boolean;
  isContentType?: boolean;
  isSize?: boolean;
}

export interface DataTableProps {
  store: any;
  pk: string;
  columns: IColumn[];
  width?: any;
  rowWidth?: any;
  detailsButtonAction?: (item: any) => void;
  previewButtonAction?: (item: any) => void;
  downloadButtonAction?: (item: any) => void;
  updateButtonAction?: (item: any) => void;
  onRowClick?: (item: any) => void;
}

const DocumentTable = (props: DataTableProps) => {

  const { store, columns } = props;

  const TableHeaderCells = () => {
    return (
      <TableRow width={props.rowWidth}>
        {columns.map((column: any) => {
          return (
            <TableCell as="th" key={column.name} color={'#FFFFFF'} >
              {column.name}
            </TableCell>
          );
        })}
        <TableCell as="th" color={'#FFFFFF'} >Action</TableCell>
      </TableRow>
    );
  };

  return (
    <Flex direction={{ base: 'row', large: 'column' }}>
      <View marginTop='10px' marginLeft='auto' marginRight='auto' width={props.width ? props.width : '90%'} height='100vh'>
        <Table
          highlightOnHover={true}
          size='small'
          border='1px solid black'
          borderRadius={5}
          width={props.width}
          overflow='hidden'
        >
          <TableHead>
            <TableHeaderCells />
          </TableHead>
          <TableBody>
            {store.map((item: any) => (
              <TableRow key={item[props.pk]} onClick={() => props.onRowClick!(item)} color={'#FFFFFF'}>
                {columns.map((column: any) => {
                  if (column.isEpoch) {
                    return (
                      <TableCell key={column.name} color={'#FFFFFF'} >{unixToDateTime(item[column.key])}</TableCell>
                    );
                  } else if (column.isContentType) {
                    return (
                      <TableCell key={column.name} color={'#FFFFFF'} >{documentTypeCheck(item[column.key])}</TableCell>
                    );
                  } else if (column.isSize) {
                    return (
                      <TableCell key={column.name} color={'#FFFFFF'} >{bytesToKb(item[column.key])}</TableCell>
                    );
                  } else {
                    return (
                      <TableCell key={column.name} color={'#FFFFFF'} >{item[column.key]}</TableCell>
                    );
                  }
                })}
                <TableCell color={'#FFFFFF'}>
                  <Flex direction='row' width='fit-content'>
                    {props.detailsButtonAction && (
                      <Button onClick={() => props.detailsButtonAction!(item)} size='small' color={'#FFFFFF'} >History</Button>
                    )}
                    {props.previewButtonAction && (
                      <Button onClick={() => props.previewButtonAction!(item)} size='small' color={'#FFFFFF'} >Preview</Button>
                    )}
                    {props.downloadButtonAction && (
                      <Button onClick={() => props.downloadButtonAction!(item)} size='small' color={'#FFFFFF'} >Download</Button>
                    )}
                    {props.updateButtonAction && (
                      <Button onClick={() => props.updateButtonAction!(item)} size='small' color={'#FFFFFF'} >Update</Button>
                    )}
                  </Flex>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </View>
    </Flex>
  );
};

export default observer(DocumentTable);