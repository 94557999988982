import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { CurrentUser, CurrentUserModel } from '../current-user/current-user';
import { DocumentStoreModel } from '../document-store/document-store';
import { DocumentVersionStoreModel } from '../document-version-store/document-version-store';
import { OrgStoreModel } from '../org-store/org-store';

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model('RootStore').props({
  currentUser: types.optional(CurrentUserModel, { isAuthenticated: false } as CurrentUser),
  documentStore: types.optional(DocumentStoreModel, {} as any),
  documentVersionStore: types.optional(DocumentVersionStoreModel, {} as any),
  orgStore: types.optional(OrgStoreModel, {} as any),
});

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
