import { View } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useStores } from '../models';


export interface ProtectedRouteProps {
  /**
     * @description The component to render if the user is authenticated.
     */
  children: any;
}

/**
 * @description A route that requires authentication.
 * @param props
 * @returns Initial Component or Redirect to Login
 */
export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { currentUser } = useStores();
  if (!currentUser?.isAuthenticated) {
    Auth.federatedSignIn({ customProvider: 'Auth0', customState: 'mystate' });
    return <View ></View>;
  }
  return props.children;
};
