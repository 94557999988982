import { onSnapshot } from 'mobx-state-tree';
import { Environment } from '../../config';
import * as storage from '../../utils/storage';
import { RootStoreModel, RootStore } from './root-store';

/**
 * The key we'll be saving our state as within async storage.
 */
const ROOT_STATE_STORAGE_KEY_PREFIX = 'document-manager';
const __DEV__ = true;
/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export async function createEnvironment() {
  const env = new Environment();
  await env.setup();
  return env;
}

/**
 * Setup the root state.
 */
export async function setupRootStore() {
  let rootStore: RootStore;
  let data: any;

  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment();
  // if there's any problems loading, then let's at least fallback to an empty state
  // instead of crashing.
  try {
    // load data from storage
    data = (await storage.load(ROOT_STATE_STORAGE_KEY_PREFIX + '-' + env.environment)) || {};
    rootStore = RootStoreModel.create(data, env);
  } catch (e: any) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    rootStore = RootStoreModel.create({}, env);

    // but please inform us what happened
    __DEV__ && console.log(e.message, null);
  }

  // track changes & save to storage
  onSnapshot(rootStore, (snapshot) => storage.save(ROOT_STATE_STORAGE_KEY_PREFIX + '-' + env.environment, snapshot));

  return rootStore;
}
