import { Text, Flex, Grid, useTheme, View, Button, Heading, TextField, Tabs, TabItem, Badge } from '@aws-amplify/ui-react';
import { Storage } from 'aws-amplify';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useStores } from '../../models';
import PdfViewer from '../pdf-viewer/pdf-viewer';
import '@aws-amplify/ui-react/styles.css';

const autoFitModal = (size: number, boarder: number) => {
  return size - size / 100 - boarder;
};

export interface AddDocumentModalProps {
  isOpen: boolean;
  closeAction: () => void;
  documentKey?: string;
  documentName?: string;
}

export interface IAddDocumentDetails {
  blob: Blob;
  fileName: string;
  type: string;
  organizationId: string;
  owner: string;
  name?: string;
  createdAt?: Date;
  tags?: { [key: string]: string };
  meta?: { [key: string]: string };
}

const AddDocumentModal = (props: AddDocumentModalProps) => {

  const { tokens } = useTheme();

  const { currentUser, documentStore } = useStores();
  const { documentUpload } = documentStore;

  const [uploadBlob, setUploadBlob] = useState<Blob | undefined>(undefined);

  const [tagName, setTagName] = useState<string | undefined>(undefined);
  const [tagValue, setTagValue] = useState<string | undefined>(undefined);

  const [metaName, setMetaName] = useState<string | undefined>(undefined);
  const [metaValue, setMetaValue] = useState<string | undefined>(undefined);

  const hiddenFileInput = useRef(null) as any;

  const handleClickDocument = (_event: any) => {
    hiddenFileInput.current.click();
  };

  // Guid Generator
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  // Date to epoch
  const getEpoch = (date: Date) => {
    return Math.floor(date.getTime() / 1000);
  };

  // Epoch to date
  const getDate = (epoch: number) => {
    return new Date(epoch * 1000);
  };

  const handleChangeDocument = (event: any, key?: string, documentName?: string) => {
    const fileUploaded = event.target.files[0];
    const defaultCreatedDate = fileUploaded.lastModified ? new Date(fileUploaded.lastModified) : new Date();
    const year = defaultCreatedDate.getFullYear();
    const month = defaultCreatedDate.getMonth() + 1;
    const day = defaultCreatedDate.getDate();
    const createdDateTime = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}` + 'T12:00:00.000Z';
    if (fileUploaded) {
      setUploadBlob(fileUploaded);
      const doc = {
        FileName: key || fileUploaded.name,
        Name: documentName || fileUploaded.name,
        Type: fileUploaded.type,
        Org: currentUser.activeOrg,
        Owner: currentUser.userKey,
        CreatedAt: getEpoch(new Date(createdDateTime)),
        CoreTags: [
          `owner=${currentUser.userKey}`,
          `org=${currentUser.activeOrg}`,
        ],
        Tags: [],
        Meta: [],
      };
      console.log(doc);
      documentStore.docUploadCreate(doc);
    }
  };

  const onClose = () => {
    setUploadBlob(undefined);
    documentStore.docUploadClear();
    props.closeAction();
  };

  const uploadDocument = (key?: string) => {
    if (uploadBlob) {
      const tags: any = [];
      documentUpload?.Tags.forEach(tag => {
        tags.push(tag);
      });
      documentUpload?.CoreTags.forEach(tag => {
        tags.push(tag);
      });

      const tagString = tags.join('&') + '&displayName=' + documentUpload!.Name + '&createdDateTime=' + getDate(documentUpload!.CreatedAt).toISOString();

      Storage.put(
        `${documentUpload?.Org}/${key || s4()}`, uploadBlob, {
          contentType: uploadBlob.type,
          level: 'public',
          tagging: tagString,
          metadata: documentUpload?.Meta as any || {},
        },
      ).then(() => {
        console.log('Uploaded successfully');
        onClose();
      }).catch(err => {
        console.log(err);
      });
    }
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={props.isOpen}
      preventScroll={true}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          color: tokens.colors.font.primary.value,
          width: autoFitModal(window.innerWidth, 100),
          height: autoFitModal(window.innerHeight, 100),
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: tokens.colors.background.primary.value,
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Grid
        templateColumns="1fr 1fr"
        templateRows="3rem 18rem 18rem"
        gap="var(--amplify-space-small)"
      >
        <View columnSpan={2} backgroundColor='transparent'>
          <Flex direction='column' justifyContent='flex-start'>
            <Text color={tokens.colors.font.primary.value} alignSelf={'center'} textAlign='center' fontSize={tokens.fontSizes.xxl}>Add Document</Text>
          </Flex>
        </View>
        <View rowSpan={2} backgroundColor='transparent'>
          <Flex direction='row' justifyContent={'center'}>
            {documentUpload && documentUpload.Type === 'application/pdf' &&
                            <View>
                              <Heading color={tokens.colors.font.primary.value} >
                                {documentUpload.FileName}
                              </Heading>
                              <PdfViewer file={uploadBlob} width={400} height={600} />
                            </View>

            }
            {documentUpload && documentUpload.Type !== 'application/pdf' &&
                            <View>
                              <Heading color={tokens.colors.font.primary.value} >
                                {documentUpload.FileName}
                              </Heading>
                              <Text color={tokens.colors.font.primary.value} >
                                    Only PDF files are supported for preview
                              </Text>
                            </View>
            }
            <Button
              variation='primary'
              onClick={() => onClose()}
              style={{
                position: 'absolute',
                right: '2%',
                top: '2%',
              }}>Close</Button>
          </Flex>
          <Flex direction='row' justifyContent={'center'}>
            <Button
              onClick={handleClickDocument}
              margin={3}
            >
              {!documentUpload ? (
                <Text color={tokens.colors.font.primary.value} >Select a document </Text>
              ) : (
                <Text color={tokens.colors.font.primary.value} >Replace Document </Text>
              )}
            </Button>
            <input
              type={'file'}
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              onChange={(event) => handleChangeDocument(event, props.documentKey, props.documentName)}
            />
          </Flex>
        </View>
        <View backgroundColor="transparent">
          <Flex direction='column' justifyContent='flex-start'>
            <TextField
              color={tokens.colors.font.primary.value}
              label="Document Name"
              size='small'
              defaultValue={documentUpload?.Name}
              placeholder="Document Name"
              descriptiveText="Document name to be displayed. (Default - file name)"
              type="text"
              disabled={!documentUpload}
              width={'80%'}
              margin={3}
              onChange={(event: any) => {
                documentStore.docUploadUpdateName(event.target.value);
              }}
            />
            <TextField
              color={tokens.colors.font.primary.value}
              label="Organization"
              value={currentUser.activeOrgName}
              descriptiveText="Organization to which the document belongs to."
              type="text"
              isReadOnly={true}
              width={'80%'}
              margin={3}
            />
            <TextField
              color={tokens.colors.font.primary.value}
              label="Document Owner"
              size='small'
              value={currentUser.userKey}
              descriptiveText="Owner of the document."
              type="text"
              isReadOnly={true}
              width={'80%'}
              margin={3}
            />
          </Flex>
        </View>
        <View backgroundColor="transparent">
          <Flex direction='row' justifyContent='flex-start' marginTop={20} alignItems='center'>
            <View>
              <Text color={tokens.colors.font.primary.value} >
                                Created Date
              </Text>
              <input
                type='date'
                defaultValue={documentUpload?.CreatedAt ? getDate(documentUpload.CreatedAt).toISOString().split('T')[0] : ''}
                disabled={!documentUpload}
                onChange={(event: any) => {
                  documentStore.docUploadUpdateCreatedAt(getEpoch(new Date(event.target.value)));
                }}
              />
            </View>
            <Tabs>
              <TabItem title="Tags">
                <Flex direction='row'>
                  <TextField
                    label=""
                    size='small'
                    defaultValue={tagName}
                    disabled={!documentUpload}
                    placeholder="Tag Name"
                    type="text"
                    width={'80%'}
                    margin={3}
                    onChange={(event: any) => {
                      setTagName(event.target.value);
                    }}
                  />
                  <TextField
                    label=""
                    size='small'
                    defaultValue={tagValue}
                    disabled={!documentUpload}
                    placeholder="Tag Value"
                    type="text"
                    width={'80%'}
                    margin={3}
                    onChange={(event: any) => {
                      setTagValue(event.target.value);
                    }}
                  />
                  <Button
                    size='small'
                    margin={3}
                    disabled={!tagName || !tagValue}
                    onClick={() => {
                      documentStore.docUploadAddTag(tagName!, tagValue!);
                      setTagName('');
                      setTagValue('');
                    }}
                  >
                                        Add
                  </Button>

                </Flex>
                <Flex direction='row' wrap='wrap' marginTop={5}>
                  {documentUpload &&
                                        documentUpload.Tags &&
                                        documentUpload.Tags.map((tag: any, index: number) => {
                                          return (
                                            <Badge key={index} margin={3} variation='warning'>
                                              <Text>{tag}</Text>
                                            </Badge>
                                          );
                                        })
                  }
                </Flex>
              </TabItem>
              <TabItem title="Meta">
                <Flex direction='row'>
                  <TextField
                    label=""
                    size='small'
                    defaultValue={metaName}
                    disabled={!documentUpload}
                    placeholder="Meta Name"
                    type="text"
                    width={'80%'}
                    margin={3}
                    onChange={(event: any) => {
                      setMetaName(event.target.value);
                    }}
                  />
                  <TextField
                    label=""
                    size='small'
                    defaultValue={metaValue}
                    disabled={!documentUpload}
                    placeholder="Meta Value"
                    type="text"
                    width={'80%'}
                    margin={3}
                    onChange={(event: any) => {
                      setMetaValue(event.target.value);
                    }}
                  />
                  <Button
                    size='small'
                    margin={3}
                    disabled={!metaName || !metaValue}
                    onClick={() => {
                      documentStore.docUploadAddMeta(metaName!, metaValue!);
                      setMetaName('');
                      setMetaValue('');
                    }}
                  >
                                        Add
                  </Button>

                </Flex>
                <Flex direction='row' wrap='wrap' marginTop={5}>
                  {documentUpload &&
                                        documentUpload.Meta &&
                                        documentUpload.Meta.map((meta: any, index: number) => {
                                          return (
                                            <Badge key={index} margin={3} variation='info'>
                                              <Text>{meta}</Text>
                                            </Badge>
                                          );
                                        })
                  }
                </Flex>
              </TabItem>
            </Tabs>
          </Flex>
          {documentUpload &&
                        <Button marginTop={20} isFullWidth={true} onClick={() => uploadDocument(props.documentKey)}>
                            Upload Document
                        </Button>
          }
        </View>
      </Grid>
    </ReactModal>
  );
};

export default observer(AddDocumentModal);