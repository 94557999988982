import config from './default-config';

async function load() {
  try {
    const result = await fetch('config.json');
    const newconfig = await result.json();
    for (let prop in config) {
      if (newconfig[prop]) {
        delete config[prop];
      }
    }
    for (let prop_1 in newconfig) {
      config[prop_1] = newconfig[prop_1];
    }

    return config;
  } catch {
    return Promise.resolve(config);
  }
}
export { load };