import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const DocumentAddModel = types
  .model('DocumentAdd')
  .props({
    FileName: types.string,
    Type: types.string,
    Org: types.string,
    Owner: types.string,
    Name: types.string,
    CreatedAt: types.number,
    CoreTags: types.array(types.string),
    Tags: types.optional(
      types.array(types.string), [],
    ),
    Meta: types.optional(
      types.array(types.string), [],
    ),
  });

type DocumentAddType = Instance<typeof DocumentAddModel>;
export interface DocumentAdd extends DocumentAddType { }
type DocumentAddSnapshotType = SnapshotOut<typeof DocumentAddModel>;
export interface DocumentAddSnapshot extends DocumentAddSnapshotType { }
