import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const OrgModel = types
  .model('Org')
  .props({
    Key: types.identifier,
    Name: types.string,
  });

type OrgType = Instance<typeof OrgModel>;
export interface Org extends OrgType {}
type OrgSnapshotType = SnapshotOut<typeof OrgModel>;
export interface OrgSnapshot extends OrgSnapshotType {}
export const createOrgDefaultModel = () =>
  types.optional(OrgModel, {} as Org);
