import { DynamoApi } from '../api/dynamo-api/dynamo-api';
import { S3Api } from '../api/s3-api/s3-api';
import { AnalyticsRecorder } from '../utils/event-recorder';
import config, { Config } from './default-config';


/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */

const environment = config.environment;
const __DEV__ = environment === 'dev';

export class Environment {
  environment: string = environment;
  dynamoApi: DynamoApi;
  s3Api: S3Api;
  analyticsRecorder: AnalyticsRecorder;
  config: Config;
  constructor() {
    // create each service
    if (__DEV__) {
      // dev-only services

    }

    this.dynamoApi = new DynamoApi({
      tableName: config.storage.table, // TODO Move all this to config
      region: config.region,
      indexMap: config.storage.tableIndexMap,
    });

    this.analyticsRecorder = new AnalyticsRecorder('dev');

    this.s3Api = new S3Api({
      region: config.region,
      bucket: config.storage.bucket,
      level: 'public',
      recorder: this.analyticsRecorder,
    });

    this.config = config;
  }

  async setup() {
    // allow each service to setup
    if (__DEV__) {
    }

  }
}
