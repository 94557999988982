import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { DocumentAddModel, DocumentAddSnapshot } from '../document-add/document-add';
import { DocumentModel, DocumentSnapshot } from '../document/document';
import { withEnvironment } from '../extensions/with-environment';

export const DocumentStoreModel = types
  .model('DocumentStore')
  .props({
    documents: types.optional(types.array(DocumentModel), []),
    selectedDocument: types.maybeNull(types.reference(DocumentModel)),
    selectedDocumentPreviewUrl: types.maybeNull(types.string),
    documentUpload: types.maybeNull(DocumentAddModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    // TODO - Evaluate where this should live
    queryRecorder: (eventName: string, itemsReturned?: number, searchQuery?: string, documentName?: string, documentKey?: string) => {
      const event: any = {};

      if (itemsReturned) {
        event.itemsReturned = itemsReturned;
      }
      if (searchQuery) {
        event.searchQuery = searchQuery;
      }
      if (documentName) {
        event.documentKey = documentName;
      }
      if (documentKey) {
        event.documentKey = documentKey;
      }

      self.environment.analyticsRecorder.recordEventAsync(eventName, event);
    },
  }))
  .actions((self) => ({
    saveDocuments: (documentSnapshots: DocumentSnapshot[]) => {
      self.documents.replace(documentSnapshots);
    },
    saveSelectedDocumentPreviewUrl: (url: string | null) => {
      self.selectedDocumentPreviewUrl = url;
    },
  }))
  .actions((self) => ({
    setSelectedDocumentPreview: (key: string | null, versionId?: string) => {
      if (key) {
        self.environment.s3Api.getSignedUrl(key, versionId, false, 600, false).then(url => {
          self.saveSelectedDocumentPreviewUrl(url);
        });
      } else {
        self.saveSelectedDocumentPreviewUrl(null);
      }
    },
  }))
  .actions((self) => ({
    getDocuments: async (org: string, search?: string) => {
      const api = self.environment.dynamoApi;
      return api.queryByIndex(org, 'idx_name', search).then(res => {
        const documents: DocumentSnapshot[] = res.Items!.map(item => {
          return {
            AppPk: item.AppPk as unknown as string,
            AppSk: item.AppSk as unknown as string,
            Name: item.Name as unknown as string,
            CreatedAt: String(item.CreatedAt) as unknown as string,
            UpdatedAt: String(item.UpdatedAt) as unknown as string,
            LatestVersionId: item.VersionId as unknown as string,
            Size: item.Size as unknown as number,
            ContentType: item.ContentType as unknown as string,
            Path: item.Key as unknown as string,
          };
        });
        self.saveDocuments(documents);
        self.queryRecorder('document-query', res.Items!.length, search);
      });
    },
    setSelectedDocument: (AppPK: string | null) => {
      self.selectedDocument = self.documents.find(doc => doc.AppPk === AppPK) || null;
      if (self.selectedDocument) {
        self.queryRecorder('document-selected', undefined, undefined, self.selectedDocument.Name, self.selectedDocument.AppPk);
      } else {
        self.queryRecorder('document-unselected', undefined, undefined, undefined);
      }
    },
  }))
  .actions((self) => ({
    docUploadCreate: async (document: DocumentAddSnapshot) => {
      self.documentUpload = {
        FileName: document.FileName,
        Type: document.Type,
        Org: document.Org,
        Owner: document.Owner,
        Name: document.Name,
        CreatedAt: document.CreatedAt,
        CoreTags: document.CoreTags as any,
        Tags: document.Tags as any,
        Meta: document.Meta as any,
      };
    },
    docUploadAddTag: (key: string, value: string) => {
      if (!self.documentUpload) {
        return;
      }
      self.documentUpload.Tags.replace(
        self.documentUpload.Tags.concat([`${key}:${value}`]),
      );
    },
    docUploadAddMeta: (key: string, value: string) => {
      if (!self.documentUpload) {
        return;
      }
      self.documentUpload.Meta.replace(
        self.documentUpload.Meta.concat([`${key}:${value}`]),
      );
    },
    docUploadUpdateName: (name: string) => {
      if (!self.documentUpload) {
        return;
      }
      self.documentUpload.Name = name;
    },
    docUploadUpdateCreatedAt: (createdAt: number) => {
      if (!self.documentUpload) {
        return;
      }
      self.documentUpload.CreatedAt = createdAt;
    },
    docUploadClear: () => {
      self.documentUpload = null;
    },
  }));


type DocumentStoreType = Instance<typeof DocumentStoreModel>;
export interface DocumentStore extends DocumentStoreType { }
type DocumentStoreSnapshotType = SnapshotOut<typeof DocumentStoreModel>;
export interface DocumentStoreSnapshot extends DocumentStoreSnapshotType { }
export const createDocumentStoreDefaultModel = () =>
  types.optional(DocumentStoreModel, {} as any);
