import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';

export const CurrentUserModel = types
  .model('CurrentUser')
  .props({
    isAuthenticated: types.optional(types.boolean, false),
    activeOrg: types.optional(types.string, ''),
    activeOrgName: types.optional(types.string, ''),
    userKey: types.optional(types.string, ''),
    isAdmin: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setAuthenticated: (state: boolean) => {
      self.isAuthenticated = state;
    },
    setUserKey: (userKey: string) => {
      self.userKey = userKey;
    },
    setAdmin: (isAdmin: boolean) => {
      self.isAdmin = isAdmin;
    },
    setActiveOrgName: (name: string) => {
      self.activeOrgName = name;
    },
  }))
  .actions((self) => ({
    getActiveOrgName: async () => {
      if (self.isAdmin) {
        if (self.activeOrg === 'd1b73e2a-ce2f-4731-b36f-bfedcac3e3f8') {
          self.setActiveOrgName('Portal Parent Admin');
        } else {
          await self.environment.dynamoApi.getItem({ Key: { S: self.activeOrg } }, `${self.environment.config.environment}-KES_Customer`).then(res => {
            console.log(res);
            self.setActiveOrgName(res.Item!.Name!.S!);
          });
        }
      } else {
        self.setActiveOrgName('Customer Pool');
      }
    },
  }))
  .actions((self) => ({
    setActiveOrg: (org: string) => {
      self.activeOrg = org;
      self.getActiveOrgName();
    },
  }));

type CurrentUserType = Instance<typeof CurrentUserModel>;
export interface CurrentUser extends CurrentUserType {}
type CurrentUserSnapshotType = SnapshotOut<typeof CurrentUserModel>;
export interface CurrentUserSnapshot extends CurrentUserSnapshotType {}
export const createCurrentUserDefaultModel = () =>
  types.optional(CurrentUserModel, { isAuthenticated: false } as CurrentUser);
